import { useStaticQuery, graphql } from 'gatsby'

export const HomepageData = () => {
  const {
    wpgraphql,
    servicesMobileImg,
    servicesDesktopImg
  } = useStaticQuery(graphql`
    {
      servicesMobileImg: allFile(
        sort: { fields: name, order: ASC }
        filter: { relativeDirectory: { eq: "services/mobile" } }
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              fluid(maxWidth: 250, quality: 75) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      servicesDesktopImg: allFile(
        sort: { fields: name, order: ASC }
        filter: { relativeDirectory: { eq: "services/desktop" } }
      ) {
        edges {
          node {
            id
            name
            childImageSharp {
              fluid(maxWidth: 500, quality: 75) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      wpgraphql {
        page(id: "27", idType: DATABASE_ID) {
          homeSettings {
            sections {
              __typename
              ... on WPGraphQL_Page_Homesettings_Sections_SeoBlock {
                sTitle: title
                subTitle
                description
              }
              ... on WPGraphQL_Page_Homesettings_Sections_HeroBlock {
                heroImage {
                  altText
                  sourceUrl
                  mediaItemId
                  modified
                  imageFile {
                    childImageSharp {
                      fluid(maxWidth: 1390, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
                hTitle: title
                subTitle
                barTexts {
                  title
                }
              }
              ... on WPGraphQL_Page_Homesettings_Sections_AboutBlock {
                aTitle: title
                aboutContent: content
              }
              ... on WPGraphQL_Page_Homesettings_Sections_PillarsBlock {
                title
                tags {
                  text
                }
                bullets {
                  text
                }
                responsiveImages {
                  desktop {
                    altText
                    sourceUrl
                    mediaItemId
                    modified
                    imageFile {
                      childImageSharp {
                        fluid(maxWidth: 600, quality: 100) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                  tablet {
                    altText
                    sourceUrl
                    mediaItemId
                    modified
                    imageFile {
                      childImageSharp {
                        fluid(maxWidth: 415, quality: 100) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                  mobile {
                    altText
                    sourceUrl
                    mediaItemId
                    modified
                    imageFile {
                      childImageSharp {
                        fluid(maxWidth: 345, quality: 100) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                }
              }
              ... on WPGraphQL_Page_Homesettings_Sections_HowWeElevateBlock {
                blocks {
                  header
                  title
                  description
                  bullets {
                    text
                  }
                }
              }
              ... on WPGraphQL_Page_Homesettings_Sections_CompanyBlock {
                header
                companies {
                  altText
                  sourceUrl
                  mediaItemId
                  modified
                  imageFile {
                    childImageSharp {
                      fluid(maxWidth: 300, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
              ... on WPGraphQL_Page_Homesettings_Sections_ProfileBlock {
                businessTitle
                header
                profileContent: content {
                  paragraph
                }
                callToAction {
                  text
                  link
                  openAtNewTab
                }
                image {
                  altText
                  sourceUrl
                  mediaItemId
                  modified
                  imageFile {
                    childImageSharp {
                      fluid(maxWidth: 450, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `)
  return {
    seoData: wpgraphql.page.homeSettings.sections[0],
    heroData: wpgraphql.page.homeSettings.sections[1],
    heroImage:
      wpgraphql.page.homeSettings.sections[1].heroImage.imageFile
        .childImageSharp.fluid,
    aboutData: wpgraphql.page.homeSettings.sections[2],
    pillarsData: wpgraphql.page.homeSettings.sections[3],
    pillarsImgDesktop:
      wpgraphql.page.homeSettings.sections[3].responsiveImages.desktop,
    pillarsImgTablet:
      wpgraphql.page.homeSettings.sections[3].responsiveImages.tablet,
    pillarsImgMobile:
      wpgraphql.page.homeSettings.sections[3].responsiveImages.mobile,
    servicesData: wpgraphql.page.homeSettings.sections[4],
    servicesDesktopImages: servicesDesktopImg.edges,
    servicesMobileImages: servicesMobileImg.edges,
    companiesData: wpgraphql.page.homeSettings.sections[5],
    companiesImages: wpgraphql.page.homeSettings.sections[5].companies,
    bioData: wpgraphql.page.homeSettings.sections[6],
    bioImage:
      wpgraphql.page.homeSettings.sections[6].image.imageFile.childImageSharp
        .fluid
  }
}
