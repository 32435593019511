import { useStaticQuery, graphql } from 'gatsby'

export const ThemeData = () => {
  const { wpgraphql } = useStaticQuery(graphql`
    {
      wpgraphql {
        codThemeSettings {
          themeSettings {
            quoteText
            footerDescription
            footerEmail
            footerPhone
            footerAddress
            copyrightText
          }
        }
      }
    }
  `)
  return {
    footerData: {
      quoteText: wpgraphql.codThemeSettings.themeSettings.quoteText,
      description: wpgraphql.codThemeSettings.themeSettings.footerDescription,
      email: wpgraphql.codThemeSettings.themeSettings.footerEmail,
      phone: wpgraphql.codThemeSettings.themeSettings.footerPhone,
      address: wpgraphql.codThemeSettings.themeSettings.footerAddress,
      copyrightText: wpgraphql.codThemeSettings.themeSettings.copyrightText
    }
  }
}
