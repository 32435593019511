import React from 'react'
import styled from '@emotion/styled'
import { HomepageData } from '@hooks/homepage-data'

const Companies = styled(({ children, className }) => {
  const { companiesData, companiesImages } = HomepageData()

  return (
    <section className={className}>
      <div className="container">
        <h4 dangerouslySetInnerHTML={{ __html: companiesData.header }} />
        <div className="row justify-content-between">
          {companiesImages.map((item, index) => (
            <div className="company-logo" key={`comp-${index}`}>
              <img src={item.imageFile.childImageSharp.fluid.src} alt="logo" />
            </div>
          ))}
        </div>
      </div>
    </section>
  )
})`
  background: #ffffff;
  padding-bottom: 12px;
  padding-top: 75px;
  position: relative;

  h4 {
    color: #000000;
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 105%;
    text-align: center;
    margin-bottom: 60px;

    span {
      color: #7dd93f;
    }

    @media only screen and (min-width: 1024px) {
      font-size: 64px;
      line-height: 105%;
    }
  }

  .row{
    flex-grow: 1;
  }

  .company-logo {
    align-items: center;
    display: flex;
    justify-content: center;
    flex: 0 0 auto;
    margin-bottom: 28px;
    width: 45%;

    @media only screen and (min-width: 768px) {
      padding: 0;
      margin-bottom: 48px;
      width: 21%;
    }

    @media only screen and (min-width: 1024px) {
      width: auto;
    }

    @media only screen and (min-width: 1200px) {
      min-width: 16%;
    }

    img {
      @media only screen and (max-width: 992px) {
        max-width: 160px;
      }
    }
  }

  @media screen and (min-width: 1023px),  screen and (min-height: 767px) {
    align-items: center;
    display: flex;
    height: 100vh;
  }

  .container{
    @media only screen and (max-width: 1024px) {
      padding: 0 35px;
      max-width: 100%;
    }
  }

  @media only screen and (max-width: 576px) {
    height: auto;
  }
`

export default Companies
