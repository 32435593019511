import React, { useContext } from 'react'
import styled from '@emotion/styled'
import { MenuData } from '@hooks/menu-data'
import { number, object, string } from 'prop-types'
import scrollTo from 'gatsby-plugin-smoothscroll'

import Logo from '@images/logo.svg'
import LogoBlack from '@images/logo-black.svg'

import ThemeContext from '@contexts/themeContext'
import useWindowSize from '@helpers/custom-hooks'

const Header = styled(
  ({
    activeScreen,
    activeScreenLogo,
    scrollDirection,
    activeSection,
    fullpageApi,
    className
  }) => {
    const { menuData } = MenuData()
    const { activeMenu, toggleMenu } = useContext(ThemeContext)
    const { width } = useWindowSize()

    const logoWhite = [0, 1, 3, 5, 7, 8]
    const logoBlack = [2, 4, 6]
    const logoDisable = [7, 8]

    return (
      <header
        className={`${className} ${activeScreen !== 0 && 'bg-disable'} mobile-${
          activeSection === 0
            ? 'transparent'
            : !logoWhite.includes(activeSection)
            ? 'white'
            : !logoBlack.includes(activeSection)
            ? 'black'
            : ''
        }`}
      >
        <div className="header-inner">
          <div className="container-fluid p-0 h-100">
            <div className="row align-items-center h-100 justify-content-between">
              <div className="col-10 col-lg-2">
                <div
                  className={`logo ${
                    logoDisable.includes(activeScreen) ? 'disable' : ''
                  } `}
                >
                  <button
                    onClick={() => {
                      if (width >= 768) {
                        fullpageApi.moveTo(1, 0)
                      } else {
                        scrollTo('#hero')
                      }
                      if (activeMenu === true) {
                        toggleMenu(false)
                      }
                    }}
                  >
                    <div
                      className={` ${
                        !logoWhite.includes(
                          width >= 768 ? activeScreenLogo : activeSection
                        ) && !activeMenu
                          ? 'logo-disable'
                          : ''
                      } ${scrollDirection !== null ? scrollDirection : ''}`}
                    >
                      <Logo />
                    </div>
                    <div
                      className={`absolute-logo ${
                        !logoBlack.includes(
                          width >= 768 ? activeScreenLogo : activeSection
                        ) || activeMenu
                          ? 'logo-disable'
                          : ''
                      } ${scrollDirection ? scrollDirection : ''}`}
                    >
                      <LogoBlack />
                    </div>
                  </button>
                </div>
              </div>
              <div className="col-2 col-lg-10">
                <div
                  className={`d-none d-lg-block ${
                    activeScreen !== 0 ? 'disable' : ''
                  }`}
                >
                  <ul className="nav">
                    {menuData.map((item, itemIndex) => (
                      <li key={`menu-${itemIndex}`}>
                        <button
                          onClick={() => {
                            if (width >= 768) {
                              fullpageApi.moveTo(item.menuFields.targetIndex, 0)
                            } else {
                              scrollTo('#' + item.menuFields.targetId)
                            }
                          }}
                        >
                          {item.label}
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
                <div
                  className={`menu-wrapper ${
                    activeScreen === 0 ? 'disable' : ''
                  }`}
                >
                  <button
                    className={`menu-bar  ${activeMenu === true && 'active'} ${
                      logoDisable.includes(activeScreen) ? 'menu-white' : ''
                    } `}
                    onClick={() => {
                      toggleMenu(!activeMenu)
                    }}
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={
            'navigation-content-mobile ' + (activeMenu === true ? 'show' : '')
          }
        >
          <ul className="nav">
            {menuData.map((item, index) => (
              <li key={`menu-${index}`}>
                <button
                  onClick={() => {
                    if (width >= 768) {
                      fullpageApi.moveTo(item.menuFields.targetIndex, 0)
                    } else {
                      scrollTo('#' + item.menuFields.targetId)
                    }
                    toggleMenu(!activeMenu)
                  }}
                >
                  {item.label}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </header>
    )
  }
)`
  position: fixed;
  left: 0;
  top: 0;
  padding: 0 60px 0 100px;
  z-index: 99;
  width: 100%;

  &::before {
    content: '';
    height: 81px;
    position: absolute;
    left: 0;
    top: 0;
    transition: all 0.35s ease;
    width: 100%;
    z-index: -1;
  }

  &::after {
    bottom: 0;
    content: '';
    opacity: 0.2;
    left: 0;
    height: 1px;
    position: absolute;
    transition: all 0.35s ease;
    width: 100%;
  }

  .logo-disable {
    visibility: hidden;
    opacity: 0;
    transition: all 0.35s ease;
  }

  @media only screen and (min-width: 768px) {
    &.bg-disable {
      &::before,
      &::after {
        opacity: 0;
        visibility: hidden;
      }
    }

    .disable {
      visibility: hidden;
      opacity: 0;
      transition: all 0.35s ease;
    }

    &.mobile-transparent {
      &::before {
        display: block;
      }
    }

    &::before {
      background-color: rgba(22, 22, 22, 0.4);
    }

    &::after {
      background: #ffffff;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 991px) {
    .menu-wrapper {
      visibility: visible;
      opacity: 1;
    }
  }

  .logo {
    button {
      background-color: transparent;
      border: none;
      position: relative;

      .absolute-logo {
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: all 0.35s ease;
        left: 50%;
      }
    }
  }

  .header-inner {
    position: relative;
    height: 81px;
    z-index: 2;
  }

  .menu-wrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.35s ease;
    right: 0;
  }

  .nav {
    justify-content: flex-end;
    padding: 0;
    margin: 0;
    transition: all 0.35s ease;

    li {
      margin: 0 16px;

      button {
        background: transparent;
        border: none;
        color: #fff;
        display: block;
        font-family: 'IBM Plex Sans', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: 0.24em;
        text-decoration: none;
        text-transform: uppercase;
        padding: 30px 0;
        position: relative;

        &:focus,
        &:hover {
          outline: none;
        }

        &::after {
          content: '';
          background: #7dd93f;
          bottom: 0;
          height: 3px;
          left: 0;
          opacity: 0;
          position: absolute;
          transition: all 0.35s ease;
          width: 100%;
        }

        &:hover {
          &::after {
            opacity: 1;
          }
        }
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .navigation-content-mobile {
    align-items: center;
    background: #151f21;
    display: flex;
    justify-content: center;
    height: 100%;
    opacity: 0;
    left: 0;
    top: 0;
    padding: 81px 15px;
    position: fixed;
    visibility: hidden;
    transition: all 0.35s ease;
    width: 100%;

    &.show {
      opacity: 1;
      visibility: visible;
    }

    .nav {
      display: block;
      text-align: center;

      li {
        margin: 0;
        button {
          display: block;
          font-size: 22px;
          line-height: 25px;
          font-weight: 400;
          text-align: center;
          margin: 0 auto;

          @media only screen and (min-width: 768px) {
            font-size: 28px;
            line-height: 32px;
          }

          @media only screen and (min-width: 1024px) {
            font-size: 64px;
            line-height: 74px;
          }
        }
      }
    }
  }

  .menu-bar {
    background-color: transparent;
    border: none;
    display: block;
    height: 32px;
    padding: 0;
    position: relative;
    margin-left: auto;
    width: 40px;

    span {
      background: #7dd93f;
      display: block;
      height: 4px;
      position: absolute;
      transition: all 0.35s ease;
      width: 40px;

      &:nth-of-type(1) {
        top: 0;
      }

      &:nth-of-type(2) {
        top: 14px;
      }

      &:nth-of-type(3) {
        top: 28px;
      }
    }

    &.menu-white {
      @media only screen and (min-width: 768px) {
        &:before {
          background-color: #fff;
          content: '';
          height: 80px;
          position: absolute;
          transform: translate(-50%, -50%);
          width: 80px;
        }
      }
    }

    &.active {
      span {
        &:nth-of-type(1) {
          top: 14px;
          transform: rotate(-45deg);
        }
        &:nth-of-type(2) {
          transform: rotate(45deg);
        }
        &:nth-of-type(3) {
          opacity: 0;
        }
      }
    }
  }

  @media only screen and (max-width: 991px) {
    padding: 0 15px;
    position: fixed;

    .logo {
      img {
        display: block;
        width: 157px;
      }
    }
  }

  @media screen and (min-width: 992px) and (max-width: 1200px) {
    padding: 0 15px 0 50px;
  }

  @media only screen and (min-width: 768px) {
    .down {
      transition-delay: 0.5s !important;
    }
  }

  @media only screen and (max-width: 767px) {
    &.mobile-black {
      &::before {
        transition: all 0.1s ease;
        background-color: rgba(22, 22, 22, 0.7);
      }
    }

    &.mobile-white {
      &::before {
        transition: all 0.1s ease;
        background-color: rgba(255, 255, 255, 0.7);
      }
    }

    &.mobile-transparent {
      &::before {
        display: none;
      }
      &::after {
        background: #ffffff;
        opacity: 0.4;
      }
      .menu-bar span {
        background: #fff;
      }
    }
  }
`
Header.propTypes = {
  activeScreen: number.isRequired,
  activeScreenLogo: number.isRequired,
  activeSection: number.isRequired,
  scrollDirection: string,
  fullpageApi: object
}

Header.defaultProps = {
  data: {},
  scrollDirection: ''
}

export default Header
