import React from 'react'
import { string } from 'prop-types'
import Helmet from 'react-helmet'

import { HomepageData } from '@hooks/homepage-data'

const SEO = ({ title, description }) => {
  const { seoData } = HomepageData()

  const metaTitle = title || seoData.sTitle
  const metaDescription = description || seoData.description
  const metaSubTitle = seoData.subTitle

  return (
    <Helmet
      title={metaTitle}
      titleTemplate={`${metaSubTitle} - %s`}
      meta={[
        {
          name: `description`,
          content: metaDescription
        }
      ]}
    >
    </Helmet>
  )
}

SEO.propTypes = {
  title: string,
  description: string
}

SEO.defaultProps = {
  title: null,
  description: null
}

export default SEO
