import React, { useState } from 'react'
import ReactFullpage from '@fullpage/react-fullpage'

//Components
import Seo from '@components/Seo'
import Header from '@components/Header'
import Hero from '@components/Hero'
import About from '@components/About'
import Pillars from '@components/Pillars'
import Service from '@components/Service'
import Companies from '@components/Companies'
// import Bio from '@components/Bio'
import Footer from '@components/Footer'
import { HomepageData } from '@hooks/homepage-data'

//Provider
import ThemeProvider from '@providers/themeProvider'

import useWindowSize, { useScroll } from '@helpers/custom-hooks'

const pluginWrapper = () => {
  // eslint-disable-next-line import/no-extraneous-dependencies
  require('fullpage.js/vendors/scrolloverflow')
}

const IndexPage = () => {
  const { servicesData } = HomepageData()
  const width = useWindowSize()
  const { activeSection } = useScroll()

  const [activeScreen, setActiveScreen] = useState(0)
  const [scrollDirection, setScrollDirection] = useState(null)

  const onLeave = (origin, destination, direction) => {
    setActiveScreen(destination.index)
    setScrollDirection(direction)
  }

  const afterLoad = (origin, destination, direction) => {
    setActiveScreen(destination.index)
    setScrollDirection(direction)
  }

  return (
    <div>
      <Seo />
      <ReactFullpage
        autoScrolling={width >= 768 ? false : true}
        scrollOverflow={true}
        css3={false}
        easing={'easeInOutCubic'}
        scrollingSpeed={700}
        afterLoad={afterLoad}
        onLeave={onLeave}
        responsiveWidth={768}
        responsiveHeight={700}
        pluginWrapper={pluginWrapper}
        render={({ state, fullpageApi }) => {
          return (
            <div id="fullpage-wrapper">
              <ThemeProvider>
                <Header
                  activeScreen={activeScreen}
                  activeScreenLogo={activeScreen}
                  activeSection={activeSection}
                  scrollDirection={scrollDirection}
                  fullpageApi={fullpageApi}
                />
              </ThemeProvider>
              <div className="section hero-section">
                <Hero />
              </div>
              <div className="section section-padding">
                <About />
              </div>
              <div className="section section-pillars section-padding">
                <Pillars />
              </div>
              {servicesData.blocks.map((item, index) => (
                <div
                  className={`section ${
                    index % 2 == 0 ? 'black' : ''
                  } service-section-${index} section-padding`}
                  key={index}
                >
                  <Service data={item} index={index} />
                </div>
              ))}
              <div className="section white company-section section-padding">
                <Companies />
              </div>
              {/* <div className="section black section-padding">
                <Bio /> TODO:Change Contact Menu Item data index from 8 to 9 again
              </div> */}
              <div className="section fp-auto-height">
                <Footer />
              </div>
            </div>
          )
        }}
      />
    </div>
  )
}

export default IndexPage
