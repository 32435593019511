import React from 'react'
import styled from '@emotion/styled'
import { object, number } from 'prop-types'

import { HomepageData } from '@hooks/homepage-data'

const Service = styled(({ data, index, className }) => {
  const { servicesMobileImages, servicesDesktopImages } = HomepageData()

  return (
    <div
      className={`service service-${index}`}
      id={`${index === 0 && 'how-we-elevate'}`}
    >
      <div
        className="service-bg"
        css={{
          backgroundImage: `url(${servicesDesktopImages[index].node.childImageSharp.fluid.src})`
        }}
      ></div>
      <div className="container">
        <h4
          className={`services-title ${index != 0 ? 'd-none d-md-block' : ''}`}
          dangerouslySetInnerHTML={{ __html: data.header }}
        ></h4>
        <div className="row justify-content-end">
          <div className="col-md-6">
            <div className="d-md-none">
              <img
                className="services-image"
                alt={data.subtitle}
                src={
                  servicesMobileImages[index].node.childImageSharp.fluid.srcWebp
                }
              />
            </div>
            <div className="content">
              <h5 dangerouslySetInnerHTML={{ __html: data.title }}></h5>
              <h6>{data.description}</h6>
              <ul>
                {data.bullets.map((li, index) => (
                  <li key={`li-${index}`}>{li.text}</li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})``

Service.propTypes = {
  index: number.isRequired,
  data: object.isRequired
}

Service.defaultProps = {
  data: {}
}

export default Service
