import React from 'react'
import styled from '@emotion/styled'
import { HomepageData } from '@hooks/homepage-data'
import LogoIcon from '@images/logo-icon.svg'

const About = styled(({ children, className }) => {
  const { aboutData } = HomepageData()

  return (
    <section className={className} id="about">
      <div className="container">
        <div className="row">
          <div className="col-md-5 col-lg-6">
            <LogoIcon />
          </div>
          <div className="col-md-7 col-lg-6">
            <div className="about-inner">
              <h3 dangerouslySetInnerHTML={{ __html : aboutData.aTitle}}></h3>
              <div className="text" dangerouslySetInnerHTML={{ __html : aboutData.aboutContent}}></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
})`
  padding: 120px 0;

  @media only screen and (min-width: 768px) {
    align-items: center;
    display: flex;
    height: 100vh;
    padding: 50px 0;
  }

  .about-inner{
    h3 {
      color: #ffffff;
      font-family: 'DM Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 36px;
      line-height: 38px;
      letter-spacing: 0.01em;
      margin-bottom: 32px;

      @media only screen and (min-width: 768px) {
        font-size: 48px;
        line-height: 50px;
      }

      span {
        color: #a0ed6d;
      }
    }

    .text {
      color: #b0b0b0;
      font-family: 'IBM Plex Sans';
      font-size: 20px;
      line-height: 26px;
      font-style: normal;
      font-weight: normal;
      letter-spacing: 0.01em;

      @media only screen and (min-width: 768px) {
        font-size: 20px;
        line-height: 26px;
      }
    }
  }

  img, svg {
    height: auto;
    margin-bottom: 24px;
    width: 90px;

    @media only screen and (min-width: 768px) {
      margin-bottom: 0;
      width: 265px;
    }

    @media only screen and (min-width: 992px) {
      width: 397px;
    }

    @media only screen and (min-width: 1200px) {
      width: 479px;
    }
  }

  @media only screen and (max-width: 768px) {
    .row{
      align-items: center;
    }
  }
`

export default About
