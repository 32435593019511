import { useStaticQuery, graphql } from 'gatsby'

export const MenuData = () => {
  const { wpgraphql } = useStaticQuery(graphql`
    {
      wpgraphql {
        menu(id: "2", idType: DATABASE_ID) {
          menuItems {
            nodes {
              label
              menuFields {
                targetId
                targetIndex
              }
            }
          }
        }
      }
    }
  `)
  return {
    menuData: wpgraphql.menu.menuItems.nodes
  }
}
