import React from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { HomepageData } from '@hooks/homepage-data'

const Hero = styled(({ children, className }) => {
  const { heroData, heroImage } = HomepageData()

  const backgroundStyles = css`
    background: url(${heroImage.srcWebp});
  `

  return (
    <section className={className} id="hero">
      <div css={backgroundStyles} className="hero-bg" />
      <div className="container h-100">
        <div className="row align-items-center h-100">
          <div className="col-md-11 col-lg-9 col-xl-7">
            <div className="hero-inner">
              <h1 dangerouslySetInnerHTML={{ __html: heroData.hTitle }} />
              <p>{heroData.subTitle}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="hero-bar-wrap">
        <div className="container hero-bar-container">
          <div className="bar">
            <div className="bar-text">
              {heroData.barTexts.map((list, index) => {
                return index !== heroData.barTexts.length - 1 ? (
                  <React.Fragment key={`tab-${index}`}>
                    {list.title}
                    {` `}
                    <span>|</span>
                    {` `}
                  </React.Fragment>
                ) : (
                  <React.Fragment key={`tab-${index}`}>
                    {list.title}
                  </React.Fragment>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
})`
  position: relative;
  height: 100vh;

  @media only screen and (min-width: 1200px) {
    height: 100vh;
    margin-left: auto;
    width: calc(100% - 50px);
    z-index: 1;
  }

  .hero-bg {
    background-size: cover;
    background-position: center center;
    height: calc(100% - 45px);
    position: absolute !important;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;

    &::before {
      content: '';
      background: linear-gradient(
        0.61deg,
        #151f21 -1.43%,
        rgba(21, 31, 33, 0) 100.72%
      );
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    @media only screen and (min-width: 768px) {
      height: calc(100% - 73px);

      &::before {
        display: none;
      }
    }

    @media only screen and (min-width: 1200px) {
      height: calc(100% - 83px);
    }
  }

  .hero-inner {
    padding: 0 18px 100px;

    @media only screen and (min-width: 768px) {
      position: relative;
      margin-bottom: 83px;
    }

    @media only screen and (min-width: 1200px) {
      left: 72px;
    }

    h1 {
      color: #ffffff;
      font-family: 'DM Sans';
      font-style: normal;
      font-size: 36px;
      font-weight: bold;
      line-height: 116%;
      letter-spacing: 0.02em;
      margin-bottom: 16px;

      @media only screen and (min-width: 768px) {
        font-size: 80px;
        line-height: 116%;
        letter-spacing: 0.02em;
        margin-bottom: 32px;
      }

      span {
        color: #7dd93f;
        display: inline-block;
        margin-left: 5px;
      }
    }

    p {
      color: #ffffff;
      font-family: 'DM Sans';
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 27px;
      padding: 0;
      margin: 0;

      @media only screen and (min-width: 768px) {
        font-size: 28px;
        line-height: 105%;
      }

      @media only screen and (min-width: 1200px) {
      }
    }
  }

  .hero-bar-wrap {
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;

    .bar {
      display: block;
      padding: 0 23px 0 33px;
      position: relative;

      @media only screen and (min-width: 768px) {
        background: #151f21;
        box-shadow: 0px -6px 34px rgba(0, 0, 0, 0.25);
        padding: 30px 56px;
      }

      @media only screen and (min-width: 1200px) {
        padding: 57px 65px 58px 75px;
        margin: 0 -12px 0;
      }

      .bar-text {
        color: #ffffff;
        font-family: 'IBM Plex Sans';
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 26px;
        letter-spacing: 0.01em;

        @media only screen and (min-width: 768px) {
          font-size: 24px;
          line-height: 31px;
        }

        span {
          border-bottom: 1px solid #505050;
          color: #505050;
          display: block;
          height: 1px;
          margin-top: 10px;
          margin-bottom: 10px;
          text-indent: -9999px;

          @media only screen and (min-width: 768px) {
            border: none;
            display: inline-block;
            text-indent: unset;

            &:nth-of-type(2) {
              display: block;
              text-indent: -999px;
            }
          }

          @media only screen and (min-width: 1200px) {
            display: inline-block !important;
            text-indent: unset;

            &:nth-of-type(2) {
              text-indent: unset;
            }
          }
        }
      }

      &::before {
        content: '';
        background: #7dd93f;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        width: 10px;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .hero-bar-container {
      padding: 0;
    }
  }

  @media screen and (min-width: 768px) and (max-width: 991px) {
    .hero-bar-container {
      margin-left: auto !important;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
      max-width: calc(100vw - (100vw - 720px)) !important;
    }
  }

  @media screen and (min-width: 992px) and (max-width: 1200px) {
    .hero-bar-container {
      margin-left: auto !important;
      margin-right: 0;
      padding-left: 0;
      padding-right: 0;
      max-width: calc(100vw - (100vw - 960px)) !important;
    }
  }
`

export default Hero
