import React from 'react'
import styled from '@emotion/styled'
import { ThemeData } from '@hooks/theme-data'
import Logo from '@images/logo.svg'
import Quote from '@images/quote.svg'

const Footer = styled(({ children, className }) => {
  const { footerData } = ThemeData()

  return (
    <footer className={className} id="contact-us">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="quote">
              <Quote />
              <div
                dangerouslySetInnerHTML={{ __html: footerData.quoteText }}
              ></div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="footer-right">
              <div className="logo mb-4">
                <a href="/">
                  <Logo />
                </a>
              </div>
              <div className="contact">
                <p
                  dangerouslySetInnerHTML={{ __html: footerData.description }}
                />
                <p>
                  E:{' '}
                  <a href={`mailto:${footerData.email}`}>{footerData.email}</a>
                </p>
                <p>
                  P: <a href={`tel:${footerData.phone}`}>{footerData.phone}</a>
                </p>
                <p
                  dangerouslySetInnerHTML={{
                    __html: `A: ${footerData.address}`
                  }}
                />
              </div>
              <div className="all-rights">{footerData.copyrightText}</div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
})`
  background-color: #000;
  position: relative;

  &::before {
    background: #151f21;
    content: '';
    height: calc(100% - 80px);
    left: calc(50% + 16px);
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 3px;
  }

  .quote {
    color: #ffffff;
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 48px;
    line-height: 50px;
    letter-spacing: 0.01em;
    padding-top: 26px;
    padding-left: 51px;
    position: relative;

    span {
      color: #7dd93f;
    }
    div {
      position: relative;
      z-index: 1;
    }

    img,
    svg {
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .footer-right {
    padding-bottom: 63px;
    padding-top: 54px;
    padding-left: 60px;

    img {
      margin-bottom: 48px;
    }

    .contact {
      margin-bottom: 46px;

      p {
        color: #f5f5f5;
        font-family: 'IBM Plex Sans';
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 27px;
        letter-spacing: 0.04em;
        margin-bottom: 2px;

        a {
          color: #7dd93f;
        }
      }
    }

    .all-rights {
      color: #b0b0b0;
      font-family: 'IBM Plex Sans';
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.02em;
    }
  }

  @media only screen and (max-width: 991px) {
    padding-top: 56px;

    .quote {
      border-bottom: 1px solid #505050;
      font-size: 36px;
      line-height: 105%;
      padding: 0 23px 48px;
      text-align: center;

      img,
      svg {
        display: block;
        position: relative;
        margin: 0 auto 16px;
      }
    }

    &::before {
      display: none;
    }

    .footer-right .contact p {
      white-space: break-spaces;
    }

    .footer-right {
      padding: 48px 23px 56px;
      text-align: center;

      p {
        &:first-of-type {
          margin-bottom: 12px;
        }
      }
    }
  }
`

export default Footer
