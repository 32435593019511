import React from 'react'
import styled from '@emotion/styled'
import { HomepageData } from '@hooks/homepage-data'

const Pillars = styled(({ children, className }) => {
  const {
    pillarsData,
    pillarsImgDesktop,
    pillarsImgTablet,
    pillarsImgMobile
  } = HomepageData()

  return (
    <section className={className} id="ir-campaigns">
      <div className="container">
        <div className="row">
          <div className="col-md-7 col-lg-8 col-xl-7">
            <div className="pillars-inner">
              <h3 dangerouslySetInnerHTML={{ __html: pillarsData.title }} />
              <div className="text">
                {pillarsData.tags.map((list, index) => {
                  return index !== pillarsData.tags.length - 1 ? (
                    <React.Fragment key={`tag-${index}`}>
                      {list.text}
                      {` `}
                      <span>|</span>
                      {` `}
                    </React.Fragment>
                  ) : (
                    <React.Fragment key={`tab-${index}`}>
                      {list.text}
                    </React.Fragment>
                  )
                })}
              </div>
              <ol>
                {pillarsData.bullets.map((item, index) => (
                  <li
                    key={`li-${index}`}
                    dangerouslySetInnerHTML={{ __html: item.text }}
                  />
                ))}
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="pillars-right-image">
        <div className="d-none d-xl-block">
          <img
            src={pillarsImgDesktop.imageFile.childImageSharp.fluid.srcWebp}
            alt={pillarsImgDesktop.altText}
          />
        </div>
        <div className="d-none d-md-block d-xl-none">
          <img
            src={pillarsImgTablet.imageFile.childImageSharp.fluid.srcWebp}
            alt={pillarsImgTablet.altText}
          />
        </div>
        <div className="d-md-none">
          <img
            src={pillarsImgMobile.imageFile.childImageSharp.fluid.srcWebp}
            alt={pillarsImgMobile.altText}
          />
        </div>
      </div>
    </section>
  )
})`
  display: block;
  padding-bottom: 15px;
  padding-top: 120px;
  position: relative;

  &::before {
    background: rgb(241, 241, 241);
    content: '';
    height: calc(100% - 126px);
    position: absolute;
    top: 0px;
    width: 100%;
    z-index: -1;
  }

  @media only screen and (min-width: 768px) {
    padding: 50px 0;
    align-items: center;
    display: flex;
    height: 100vh;
    position: relative;
    overflow: hidden;
    z-index: 2;

    &::before {
      height: 100%;
    }
  }

  .pillars-inner {
    padding-bottom: 64px;

    @media only screen and (min-width: 992px) {
      padding-bottom: 0;
    }

    @media only screen and (min-width: 992px) {
      padding-right: 50px;
    }

    @media only screen and (min-width: 1200px) {
      padding-right: 0;
    }

    h3 {
      color: #161616;
      font-family: 'DM Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 36px;
      line-height: 37px;
      letter-spacing: 0.01em;
      padding-right: 10px;
      margin-bottom: 32px;

      @media only screen and (min-width: 768px) {
        font-size: 48px;
        line-height: 50px;
        padding-right: 0;
        margin-bottom: 24px;
      }

      span {
        color: #7dd93f;
      }
    }

    .text {
      color: #161616;
      font-family: 'IBM Plex Sans';
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 31.2px;
      letter-spacing: 0.01em;
      margin-bottom: 32px;
      span {
        color: #161616;
      }
      @media only screen and (min-width: 768px) {
        margin-bottom: 42px;
      }
    }

    ol {
      padding-right: 0;
      margin: 0;
      padding-left: 0;
      list-style: none;
      counter-reset: li;

      @media screen and (min-width: 768px) {
        padding-right: 2rem;
      }

      @media screen and (min-width: 992px) {
        padding-right: 6rem;
      }

      li {
        color: #161616;
        font-family: 'IBM Plex Sans';
        font-style: normal;
        font-weight: normal;
        font-size: 20px;
        line-height: 26px;
        letter-spacing: 0.01em;
        padding-left: 40px;
        margin-bottom: 24px;
        counter-increment: li;

        span {
          border-bottom: 2px solid #7dd93f;
          font-weight: 600;
          padding-bottom: 5px;
        }

        &:before {
          width: 40px;
          margin-left: -40px;
          display: inline-block;
          content: counter(li) '. ';
          counter-increment: item;
          color: #505050;
          font-size: 20px;
          line-height: 26px;
        }
      }
    }
  }

  .pillars-right-image {
    top: 0;
    position: relative;
    margin-left: auto;
    max-width: calc(100% - 30px);
    width: fit-content;
    transform: none;

    &::after {
      background: rgb(160, 237, 109);
      bottom: -15px;
      content: '';
      clip-path: polygon(0px 0px, 100% 0px, 100% 76%, 0% 100%);
      height: 255px;
      right: 0px;
      position: absolute;
      width: calc(100% - 20px);
      z-index: -1;
    }

    @media screen and (min-width: 768px) {
      position: absolute;
      top: 50%;
      max-width: none;
      right: 0;
      transform: translateY(-50%);
      width: 40%;

      &::after {
        display: none;
      }
    }

    img {
      @media only screen and (min-width: 1200px) {
        width: 565px;
      }
    }
  }
`

export default Pillars
