import React, { useState } from 'react'
import { node } from 'prop-types'

import ThemeContext from '@contexts/themeContext'

const ThemeProvider = ({ children }) => {
  const toggleMenu = () => {
    setOpen((prevState) => {
      return {
        ...prevState,
        activeMenu: !prevState.activeMenu
      }
    })
  }

  const loadingState = {
    activeMenu: false,
    toggleMenu
  }

  const [open, setOpen] = useState(loadingState)

  return <ThemeContext.Provider value={open}>{children}</ThemeContext.Provider>
}

ThemeProvider.propTypes = {
  children: node
}

ThemeProvider.defaultProps = {
  children: null
}

export default ThemeProvider
