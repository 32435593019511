/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useEffect } from 'react'

export default function useWindowSize() {
  const isSSR = typeof window === 'undefined'
  const [windowSize, setWindowSize] = React.useState({
    width: isSSR ? 1200 : window.innerWidth,
    height: isSSR ? 800 : window.innerHeight
  })

  function changeWindowSize() {
    setWindowSize({ width: window.innerWidth, height: window.innerHeight })
  }

  React.useEffect(() => {
    window.addEventListener('resize', changeWindowSize)

    return () => {
      window.removeEventListener('resize', changeWindowSize)
    }
  }, [])

  return windowSize
}

export function useScroll() {
  const [scrollY, setScrollY] = useState(0)
  const [activeSection, setActiveSection] = useState(0)
  const [scrollX, setScrollX] = useState(0)
  const [scrollDirection, setScrollDirection] = useState()

  if (typeof window !== 'undefined') {
    const [lastScrollTop, setLastScrollTop] = useState(0)
    const [bodyOffset, setBodyOffset] = useState(
      document.body.getBoundingClientRect()
    )

    const sections = document.querySelectorAll('.section')
    let sectionOffset = []

    sections.forEach((section, index) => {
      sectionOffset.push({
        index: index,
        top: section.getBoundingClientRect().top + scrollY,
        height: section.clientHeight,
        bottom:
          section.getBoundingClientRect().top + scrollY + section.clientHeight
      })
    })

    const listener = (e) => {
      setBodyOffset(document.body.getBoundingClientRect())
      setScrollY(-bodyOffset.top)
      setScrollX(bodyOffset.left)
      setScrollDirection(lastScrollTop > -bodyOffset.top ? 'down' : 'up')
      setLastScrollTop(-bodyOffset.top)

      sectionOffset.forEach((section, index) => {
        let customScroll = scrollY + 100
        if (customScroll > section.top && customScroll < section.bottom) {
          if (activeSection != index) {
            setActiveSection(index)
          }
        }
      })
    }

    useEffect(() => {
      window.addEventListener('scroll', listener)
      return () => {
        window.removeEventListener('scroll', listener)
      }
    })
  }

  return {
    scrollY,
    scrollX,
    scrollDirection,
    activeSection
  }
}
